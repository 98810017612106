import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";

export const compilerApi = createApi({
  reducerPath: "compilerApi",
  baseQuery: axiosBaseQuery({baseUrl:"https://api-cpl.mistercoding.com/v1"}),
  endpoints: (builder) => ({
    compile: builder.mutation<string, any>({
        query(data) {
          return {
            url: "/compile",
            method: "POST",
            data: data
          };
        },
        transformResponse: (response: any) => response ?? null,
      })
  }),
});

export const { useCompileMutation  } = compilerApi;
export default compilerApi;
