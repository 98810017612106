import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Slider.css'
import { FC, ReactNode } from 'react';
import { Box, Button, useMediaQuery } from '@chakra-ui/react';

const SliderItem = SwiperSlide;

type Props = {
    children: ReactNode,
    slideViewLarge?: number;
    isNav?: boolean;
    isPagination?: boolean
}

const Slider: FC<Props> = ({ children,slideViewLarge, isNav, isPagination }) => {

    const [isLargerThan600] = useMediaQuery('(min-width: 800px)')

    return (
        <>
            <Box overflow={"hidden"}>
                <Box>
                    <Swiper className='swp' style={{ paddingTop: 10, paddingBottom: 10 }}
                        modules={[Navigation, Pagination]}
                        pagination={isPagination??false}
                        spaceBetween={isLargerThan600 == true ? 16 : -18}
                        autoplay={true}
                        navigation={isLargerThan600 ? isNav??false : false}
                        breakpoints={{
                            400: {
                                // width: 576,
                                slidesPerView: 1.2,
                            },
                            576: {
                                // width: 576,
                                slidesPerView: 1.2,
                            },
                            768: {
                                // width: 768,
                                slidesPerView: slideViewLarge??4.2,
                            },
                        }}
                    >
                        {children}

                    </Swiper>
                </Box>
            </Box>
        </>
    )
}

export { Slider, SliderItem };