import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import { Playground, PlaygroundSubmateri } from "model/playground";
import { Exercise } from "model/exercise";

export const playgroundApi = createApi({
  reducerPath: "playgroundApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPlaygrounds: builder.query<Playground[], void>({
      query() {
        return {
          url: "/learn/playground/all",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getExercises: builder.query<Exercise[], void>({
      query() {
        return {
          url: "/learn/exercise/all",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    submitExercise: builder.mutation<any, any>({
      query(data) {
        return {
          url: "/learn/exercise/submit",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getExerciseDetail: builder.query<Exercise, any>({
      query(id) {
        return {
          url: "/learn/exercise/detail/" + id,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getContent: builder.query<PlaygroundSubmateri, any>({
      query(id) {
        return {
          url: "/learn/playground/content/" + id,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
  }),
});

export const {
  useGetPlaygroundsQuery,
  useLazyGetContentQuery,
  useGetExercisesQuery,
  useLazyGetExerciseDetailQuery,
  useSubmitExerciseMutation
} = playgroundApi;
export default playgroundApi;
