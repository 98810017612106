import { reviewApi } from './../../src/store/queries/review.api';
import { discussApi } from './queries/discuss.api';
import { couponApi } from './queries/coupon.api';
import { authApi } from './queries/auth.api';
import { kelasApi } from "./queries/kelas.api";
import {
  configureStore,
} from "@reduxjs/toolkit";
import learnApi from './queries/learn.api';
import orderApi from './queries/order.api';
import quizApi from './queries/quiz.api';
import blogApi from './queries/blog.api';
import settingApi from './queries/setting.api';
import sharingApi from './queries/sharing.api';
import LeaderboardApi from './queries/leaderboard.api';
import eventApi from './api/event.api';
import jsonApi from './api/json.api';
import subscribeApi from './api/subscribe.api';
import campaignApi from './api/campaign.api';
import flashsaleApi from './api/flashsale.api';
import commonApi from './api/common.api';
import compilerApi from './api/compiler.api';
import playgroundApi from './api/playground.api';



const stores = configureStore({
  reducer: {
    [kelasApi.reducerPath]: kelasApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [learnApi.reducerPath]: learnApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [quizApi.reducerPath]: quizApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [discussApi.reducerPath]: discussApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [sharingApi.reducerPath]: sharingApi.reducer,
    [LeaderboardApi.reducerPath]: LeaderboardApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [jsonApi.reducerPath]: jsonApi.reducer,
    [subscribeApi.reducerPath]: subscribeApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [flashsaleApi.reducerPath]: flashsaleApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [compilerApi.reducerPath]: compilerApi.reducer,
    [playgroundApi.reducerPath]: playgroundApi.reducer,
    
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(kelasApi.middleware)
      .concat(authApi.middleware)
      .concat(learnApi.middleware)
      .concat(orderApi.middleware)
      .concat(quizApi.middleware)
      .concat(blogApi.middleware)
      .concat(couponApi.middleware)
      .concat(discussApi.middleware)
      .concat(reviewApi.middleware)
      .concat(settingApi.middleware)
      .concat(sharingApi.middleware)
      .concat(LeaderboardApi.middleware)
      .concat(eventApi.middleware)
      .concat(jsonApi.middleware)
      .concat(subscribeApi.middleware)
      .concat(campaignApi.middleware)
      .concat(flashsaleApi.middleware)
      .concat(commonApi.middleware)
      .concat(compilerApi.middleware)
      .concat(playgroundApi.middleware)
  },
});
export type RootState = ReturnType<typeof stores.getState>;

export default stores;
