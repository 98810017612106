import {
  Icon,
  keyframes,
  Circle,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {  useState } from "react";
import { FaPlay } from "react-icons/fa";

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(1.2) rotate(0); opacity: 0.7; }
  75% { transform: scale(1);}
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;

const PlayerButton = () => {
  return (
    <>
      <Circle
        bgColor="rgba(225, 225, 225, 0.3)"
        p={3}
        _hover={{ opacity: 0.92 }}
        as={motion.div}
        animation={animation}
      >
        <Circle
          bgGradient={"linear(to-r, purple.400, purple.600)"}
          p={{ sm: 3, base: 2 }}
        >
          <Icon
            color={"white"}
            as={FaPlay}
            fontSize={{ sm: "2xl", base: "xl" }}
          />
        </Circle>
      </Circle>
    </>
  );
};

export default PlayerButton;
